import axios from "axios";
import store from "../store";

// Add a 401 response interceptor
axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (401 === error.response.status) {
      store.dispatch("loggedOut");
    }
    return Promise.reject(error);
  }
);

export function getReports(limit, page, keywords, onFetched) {
  var params = {
    limit,
    skip: (page - 1) * limit,
  };

  if (keywords) {
    params.keywords = keywords;
  }

  axios.get("/api/portal/reports", { params }).then((resp) => {
    var items = resp.data.results;
    var totalPages = Math.ceil(resp.data.count / limit);
    onFetched(items, totalPages);
  });
}

export function getReport(id, onFetched) {
  axios.get(`/api/portal/reports/${id}`).then((resp) => {
    onFetched(resp.data);
  });
}

export function deleteAllReports(onDone) {
  axios.post(`/api/portal/reports/delete_all`).then(() => {
    onDone();
  });
}

export function login(username, password, onLogin) {
  axios.post("/api/portal/login", { username, password }).then((resp) => {
    onLogin(resp.status === 200, resp.data);
  });
}

export function logout(onLogout) {
  axios.post("/api/portal/logout").then((resp) => {
    onLogout(resp.status === 200);
  });
}
